define('components/insuredRegistration/register/app',['require','common/directive/app','components/insuredRegistration/register/directive/register','components/insuredRegistration/register/controller/RegisterController'],function(require) {
	"use strict";

	require("common/directive/app");

	var register = require("components/insuredRegistration/register/directive/register"),
		RegisterController = require("components/insuredRegistration/register/controller/RegisterController");

	var component = angular.module("mPortal.insuredRegistration.register", [
		"mPortal.directive"
	]);

	component.directive("register", register);
	component.controller("RegisterController", RegisterController);

	return component;

});
