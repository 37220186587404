define('components/insuredRegistration/register/controller/RegisterController',['require'],function(require) {
	"use strict";

	RegisterController.$inject = ["$scope", "$window", "InsuredRegistrationService"];

	function RegisterController( $scope, $window, InsuredRegistrationService ) {

		$scope.form = {
			userName: "",
			firstName: "",
			lastName: "",
			email: "",
			emailConfirmation: "",
			password: "",
			passwordConfirmation: "",
			securityQuestionId: "",
			securityQuestionAnswer: ""
		};

		$scope.uiState = {
			errors: {},
			submitting: false
		};

		$scope.questions = [];

		InsuredRegistrationService.getSecurityQuestions().then(function( questions ) {
			$scope.questions = questions;
			$scope.form.securityQuestionId = $scope.questions[0].id;
		});

		$scope.submit = function() {
			$scope.uiState.errors = {};
			$scope.uiState.submitting = true;

			InsuredRegistrationService.createUser( $scope.form ).then(function( result ) {
				$scope.onNextStep();
			}).catch(function( err ) {
				if( err.status === 409 ) {
					$scope.goToStep( "accountLookup" );
				}

				if( err.status === 422 ) {
					$scope.uiState.errors = err.data;
					$window.scrollTo(0, 0);
				}
			}).finally(function() {
				$scope.uiState.submitting = false;
			});
		};

	}

	return RegisterController;

});
