define('components/insuredRegistration/register/directive/register',['require'],function(require) {
	"use strict";

	function Register() {
		return {
			restrict: "AE",
			scope: {
				onNextStep: "=",
				goToStep: "="
			},
			templateUrl: "app/components/insuredRegistration/register/template/template.html",
			controller: "RegisterController"
		};
	}

	return Register;

});
