define('login/controller/RegisterController',['require'],function(require) {
	"use strict";

	RegisterController.$inject = ["$scope","$q", "$sce", "$window", "$location", "UserService","$routeParams","PublicContentService"];

	function RegisterController( $scope,$q, $sce, $window, $location, UserService,$routeParams,PublicContentService) {

		$scope.init = function()
		{
			$scope.ui    = { submitted: false, view: ''};
			$scope.error = { issue: false , locked: false};
			$scope.invalid 		 = false;
			$scope.terms 		 = '';
			$scope.email 		 = null;
		}

		$scope.init();


		//registration methods
		if(!$scope.invalid)
		{
			var p = [ UserService.magency_check_user($routeParams.guid,$routeParams.confirmationNumber)];

	        $q.all(p).then(function(results){
	   
	            var result = results[0];

	           	if(result.success)
				{
					if(result.valid)
					{
						$scope.user = result.user;
						$scope.user.guid = $routeParams.guid;
						$scope.user.confirmationNumber = $routeParams.confirmationNumber;

						PublicContentService.getAgentTermsAndConditions().then( function ( result ) {
							$scope.terms = $sce.trustAsHtml( result.terms );
							$scope.ui.view = 'terms';
						} );
						
					}
					else
					{
						//show expired code call agency services message
						$scope.invalid = true;
						$window.location = '/';
					}
				}
				else
				{
					$scope.invalid = true;
					$window.location = '/';
				}
	        });
		}

		$scope.show_terms = function()
		{
			$scope.ui.view = 'terms';
			return false;
		}

		$scope.show_form = function()
		{
			$scope.ui.view = 'registration';
			$scope.errors = {};
			return false;
		}


		$scope.update = function()
		{
			$scope.errors = {};
			
			var p = [ UserService.magency_update_user($scope.user)];

	        $q.all(p).then(function(results){
	   
	            var result = results[0];

	           	if(result.success)
				{
					$scope.confirm_password();
				}
				else
				{
					if(result.errors)
					{
						$scope.errors = result.errors;
					}
				}
	        });
		}

		$scope.validate_password = function()
		{
			return true;
		};


		$scope.confirm_password = function()
		{
			var p = [ UserService.okta_login($scope.user.userName, $scope.user.password)];

            $q.all(p).then(function(results) {
       
                var result = results[0];

                if(result.success)
                {
					$scope.redirect = result.url;
					$scope.migrate_user();
				}
				else
				{
					if(result.error == 'unauthorized')
					{
						$scope.error.issue = true;
					}

					if(result.error == 'locked')
					{
						$scope.error.locked = true;
					}
				}
			});
		};

		$scope.migrate_user = function()
		{

			//set our username to the new confirmed username
			var m = [ UserService.migrate_ciam_user($scope.user.guid,$scope.user.userName,true)];

			$q.all(m).then(function(migration_results) {
		
				var migration_result = migration_results[0];

				if(migration_result.success)
				{
					$('.processing-modal').modal('show');
					$window.location = $scope.redirect;
				}
			});
		}

		$scope.format_phone = function () {
			if($scope.user.workPhone)
			{
	            var numbers = $scope.user.workPhone.replace(/\D/g, ''),
	                char    = { 3: '-', 6: '-'};
	            if (numbers.length > 10 && numbers.substring(0, 1) == 1) {
	                numbers = numbers.substring(1, 11)
	            }
	            else {
	                numbers = numbers.substring(0, 10)
	            }

	            $scope.user.workPhone = '';

	            for (var i = 0; i < numbers.length; i++) {
	                $scope.user.workPhone += (char[i] || '') + numbers[i];
	            }
			}
        };
	}

	return RegisterController;

});
