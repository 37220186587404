define('insuredRegistration/controller/InsuredRegistrationController',['require'],function(require) {
	"use strict";

	InsuredRegistrationController.$inject = ["$scope", "$window"];

	function InsuredRegistrationController( $scope, $window ) {
		


		var steps = [ "accountLookup", "terms", "register", "done" ];

		$scope.uiState = {
			step: "accountLookup"
		};

		$scope.onNextStep = function() {
			var currentStep = steps.indexOf( $scope.uiState.step );
			var nextStep = currentStep + 1;

			// in case we're calling this from the last step for some reason
			if( nextStep > steps.length - 1 ) {
				nextStep = steps.length -1;
			}

			setStep( nextStep );
		};

		$scope.goToStep = function( step ) {
			setStep( step );
		};

		function setStep( step ) {
			var stepIdx = $.isNumeric( step ) ? step : steps.indexOf( step );

			if( stepIdx < 0 ) {
				stepIdx = 0;
			}

			$scope.uiState.step = steps[ stepIdx ];
			$window.scrollTo(0, 0);

			// redirect user to Home page
			if ( $scope.uiState.step == "done" ) {
				$window.location.reload( "/" );
			}
		}
	}

	return InsuredRegistrationController;

});
