define('login/app',['require','common/config/config','common/directive/app','shim/overmind','login/controller/LoginController','login/controller/RegisterController','common/service/user/UserProfile','common/service/user/UserService','common/service/PublicContentService','bowser'],function(require) {
	"use strict";

	require("common/config/config");
	require("common/directive/app");

	var overmind = require("shim/overmind");

	var LoginController = require("login/controller/LoginController"),
		RegisterController = require("login/controller/RegisterController"),
		UserProfile = require("common/service/user/UserProfile"),
		UserService = require("common/service/user/UserService" ),
		PublicContentService = require("common/service/PublicContentService"),
		bowser = require("bowser");;

	var app = angular.module("mPortal.login", [
		"mPortal.config",
		"mPortal.directive"
	]);

	app.controller("LoginController", LoginController);
	app.controller("RegisterController",RegisterController);
	app.factory("UserProfile", UserProfile);
	app.factory("UserService", UserService);
	app.factory("PublicContentService",PublicContentService);
	app.value("bowser", bowser);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {
		$routeProvider.when("/legacy-login", {
			templateUrl: "app/login/template/template.html",
			controller: "LoginController",
			view:'login',
			guard: function( userProfile ) {
				return !userProfile.isLoggedIn;
			}
		});
	}]);



	return app;

});
