define('components/claimsSearch/controller/ClaimsSearchController',['require'],function(require) {
	"use strict";

	ClaimsSearchController.$inject = ["$scope", "$filter", "SearchService", "SearchResultContainer", "MarketedProgramService"];

	function ClaimsSearchController( $scope, $filter, SearchService, SearchResultContainer, MarketedProgramService ) {

		$scope.searchData = {
			programs: [],
			statusOptions: [ { name: "All", value: ""} ],
			possibleClaimStatusOptions: SearchService.getClaimStatusOptions()
		};

		$scope.searchParams = {
			program: "",
			claimFirstName: "",
			claimLastName: "",
			claimNumber: "",
			occurrenceNumber: "",
			insuredName: "",
			insuredAccount: "",
			policyNumber: "",
			accidentDateFrom: "",
			accidentDateTo: "",
			occurrenceDateFrom: "",
			occurrenceDateTo: "",
			status: $scope.searchData.statusOptions[ 0 ].value,
			excludeVoided: true
		};

		$scope.selectedProgram = null;
		// Currently only relevant with ICON search results.
		$scope.searchedProgram = null;

		$scope.searchResults = new SearchResultContainer({ currentPage: 1, itemsPerPage: 25 });

		$scope.showLossRuns = function(){
			let viewLossRuns = false;
			if(localStorage.getItem('mportal-show-loss-runs'))
			{
				viewLossRuns = localStorage.getItem('mportal-show-loss-runs') === 'true';
			}	
			return viewLossRuns;
		}

		/*
		 The following implementation is a short-term solution given time constraints and a project
		 scope that makes the claims search unique amongst the advanced search options.  The long-term
		 solution should probably be take the form of a single API endpoint in Arch that provides the
		 appropriate search options based on all of the different policy systems the user has access to.
		*/
		$scope.searchDataResults.then(function( result ) {
			/*
			 The goal here is to populate the program search options based on the programs available to
			 the current user, but to break Worker's Comp ("wc") and Business Owners ("bo") into separate
			 programs in order to continue to use the separate WC and BOP claims search endpoints.
			*/

			MarketedProgramService.getMarketedPrograms().then(function( programs ) {
				
				var allowedPrograms = $filter( "filter" )( programs, MarketedProgramService.userHasAccessToProgram.bind( null, $scope.user ) );
				$scope.searchData.programs.push( { name: "--Please select a program--", value: "", claimSystem: "" } );			

				angular.forEach( allowedPrograms, function( program ) {
					if (program.referenceString === "wc" ||program.referenceString === "bop" ) {							
						$scope.searchData.programs.push( { name: program.name, value: program.referenceString, abbr: program.abbreviation, claimSystem: "icon" } );
					}									
				});
				if($scope.showLossRuns()){
					$scope.searchData.programs.push( { name: "All Other Programs", value: "-1", claimSystem: "icon" } );
				}
				/*
				 Set the starting program option to the "non-choice": the user will have to select
				 a program in order to submit the search form
				*/
				$scope.selectedProgram = $scope.searchData.programs[ 0 ];
			});
		});

		$scope.searchResults.visible = {
			claims: false,
			maverickOccurrence: false,
			iconOccurrence: false
		};

		$scope.programChanged = function() {
			// Update the program search param based on the selectedProgram value
			$scope.searchParams.program = $scope.selectedProgram.value;

			/*
			Update the claim status options based on the claim system relevant to the program and (if Maverick)
			based on the line of line of business
			*/
			if( $scope.selectedProgram.claimSystem != "" ) {
				$scope.searchData.statusOptions = $scope.selectedProgram.claimSystem === "maverick"
					? angular.extend( [], $scope.searchData.possibleClaimStatusOptions[ $scope.selectedProgram.claimSystem ][ $scope.selectedProgram.value ] )
					: angular.extend( [], $scope.searchData.possibleClaimStatusOptions[ $scope.selectedProgram.claimSystem ] );
			} else {
				$scope.searchData.statusOptions = [ { name: "All", value: "" } ];
			}
			// Changing the program resets the status choice to "All"
			$scope.searchParams.status = $scope.searchData.statusOptions[ 0 ].value;
		};

		$scope.getResults = function() {

			var promise;

			switch( $scope.selectedProgram.value ) {
				case "wc":
					promise = SearchService.getClaimsSearchResults( $scope.searchParams, $scope.searchResults );
					break;
				case "bo":
					promise = SearchService.getOccurrenceSearchResults( $scope.searchParams, $scope.searchResults );
					break;
				case "":
					$scope.searchResults.error.header = "Please select a program.";
					return;
				default:
					// Used to provide the program abbr in the search results; can't use selectedProgram since user can change that between searches.
					$scope.searchedProgram = $scope.selectedProgram;
					promise = SearchService.getICONClaimsSearchResults( $scope.searchParams, $scope.searchResults );
					break;
			}

			$scope.searchResults.visible.claims = false;
			$scope.searchResults.visible.maverickOccurrence = false;
			$scope.searchResults.visible.iconOccurrence = true;

			promise.then(function() {
				$scope.searchResults.visible.claims = $scope.selectedProgram.value === "wc";
				$scope.searchResults.visible.maverickOccurrence = $scope.selectedProgram.value === "bo";
				$scope.searchResults.visible.iconOccurrence = !$scope.searchResults.visible.claims && !$scope.searchResults.visible.maverickOccurrence;				
				
			});

		};

	}

	return ClaimsSearchController;

});
