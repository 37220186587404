define('components/insuredRegistration/termsAndConditions/controller/TermsAndConditionsController',['require'],function(require) {
	"use strict";

	TermsAndConditionsController.$inject = ["$scope", "$sce", "PublicContentService"];

	function TermsAndConditionsController( $scope, $sce, PublicContentService ) {

		PublicContentService.getInsuredTermsAndConditions().then(function( result ) {
			$scope.terms = $sce.trustAsHtml( result.terms );
		});

		$scope.submit = function() {
			$scope.onNextStep();
		};

	}

	return TermsAndConditionsController;

});
