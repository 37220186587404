define('insuredRegistration/app',['require','common/config/config','common/directive/app','components/insuredRegistration/accountLookup/app','components/insuredRegistration/termsAndConditions/app','components/insuredRegistration/register/app','shim/overmind','insuredRegistration/controller/InsuredRegistrationController','common/service/user/StartRegistrationSession','common/service/insuredRegistration/InsuredRegistrationService'],function(require) {
	"use strict";

	require("common/config/config");
	require("common/directive/app");
	require("components/insuredRegistration/accountLookup/app");
	require("components/insuredRegistration/termsAndConditions/app");
	require("components/insuredRegistration/register/app");

	var overmind = require("shim/overmind");

	var InsuredRegistrationController = require("insuredRegistration/controller/InsuredRegistrationController"),
		StartRegistrationSession = require("common/service/user/StartRegistrationSession"),
		InsuredRegistrationService = require("common/service/insuredRegistration/InsuredRegistrationService");

	var app = angular.module("mPortal.insuredRegistration", [
		"mPortal.config",
		"mPortal.directive",
		"mPortal.insuredRegistration.accountLookup",
		"mPortal.insuredRegistration.termsAndConditions",
		"mPortal.insuredRegistration.register"
	]);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {

		var resolve = {
			__getRegistrationSession: StartRegistrationSession
		};

		$routeProvider.when("/legacy-register", {
			templateUrl: "app/insuredRegistration/template/template.html",
			controller: "InsuredRegistrationController",
			guard: function( userProfile ) {
				return !userProfile.isLoggedIn;
			},
			resolve: resolve
		});
		
	}]);

	app.controller("InsuredRegistrationController", InsuredRegistrationController);
	app.factory("InsuredRegistrationService", InsuredRegistrationService);

	return app;

});
