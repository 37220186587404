define('common/service/user/StartRegistrationSession',['require'],function( require ) {
    "use strict";

    StartRegistrationSession.$inject = ["$q", "UserService" ];

    // Makes a call to Arch to retrieve a UserProfile with a portalToken value that can be passed back
    // to Arch for comparison in requests made during the insured registration process
    function StartRegistrationSession( $q, UserService ) {
        return UserService.createInsuredRegistrationUserProfile().then( function( profile ) {
            return !profile.isLoggedIn ? $q.when() : $q.reject({ data: { message: "You must log out in order to register as a policy holder." }  });
        });
    }

    return StartRegistrationSession;

});
