define('login/controller/LoginController',['require'],function(require) {
	"use strict";

	LoginController.$inject = ["$scope","$q", "$sce", "$window", "UserService", "$route", "ssoApiUrl","ciamApiUrl", "policyManagerRoot","$routeParams","ciamDomain"];

	function LoginController( $scope,$q, $sce, $window, UserService, $route, ssoApiUrl, ciamApiUrl, policyManagerRoot,$routeParams,ciamDomain) {

		$scope.ciam_domain = ciamDomain;

		$scope.init = function()
		{
			$scope.form  = { username: "", password: ""};
			$scope.ui    = { submitted: false, view: $route.current.view };

			$scope.show  = { password : false , message: false , iframe: false , return : false , resend: false};
			$scope.error = { issue: false , email : false , unique : false , expired : false , locked: false , code_required : false };

			$scope.invalid 		 = false;
			$scope.terms 		 = '';
			$scope.attempts 	 = 0;
			$scope.migrate 		 = false;
			$scope.email 		 = null;
			$scope.guid 		 = null;
			$scope.ciam_username = "";
			$scope.ciam_cache    = {};
			$scope.code_sent     = false;
		}

		$scope.init();

		function onMessageCallback( event ) {
			$scope.messageListener( event.originalEvent );
		}

		// authentication takes place in an iframe that uses the postMessage api to let us know when the user has
		// successfully logged in.
		angular.element( $window ).on( "message", onMessageCallback );

		$scope.messageListener = function( event ) {

			var eventData;

			// iframe-resizer posts non-JSON data via postMessage, so if we fail to parse the message JSON we can safely ignore it.
			try {
				eventData = JSON.parse( event.data );
			} catch( err ) {
				return;
			}

			if( eventData.messageType !== "logout" && eventData.messageType !== "login") {
				// nothing to do here, but return early and leave the event listener bound.
				return;
			}

			// unbind the onMessage event. if we don't do this, it stays bound and results in multiple executions each time
			// the event fires. example:
			// 1. log in, event bound, fires once
			// 2. log out
			// 3. log in again, event bound again, fires twice
			// etc., etc., executions increase by one each time we load LoginController.
			angular.element( $window ).off( "message", onMessageCallback );

			if( eventData.messageType === "logout"  ) {
				$route.reload();
				return;
			}

			if( eventData.messageType === "login" ) {
				$window.location.href = '/';
			}
		};

		$scope.user_blur = function()
		{
			if($scope.username != $scope.form.username)
			{
				$scope.show.password = false;
			}
		};

		$scope.check_user = function()
		{
			$scope.username = $scope.form.username;

			$scope.error.issue = false;

			if($scope.form.username)
			{
				var p = [ UserService.check_user($scope.form.username)];

                $q.all(p).then(function(results){
           
                    var result = results[0];

                   	if(result.success)
					{
						//found user check status
						if(result.migrated)
						{
							$window.location = ciamApiUrl + 'auth/login-request/mportal?email='+ encodeURIComponent($scope.form.username);
						}

						if(result.migrated == false && result.eligible)
						{
							//show password 
							$scope.show.password  = true;
							$scope.show.message   = true;
							$scope.migrate 		  = true;
							$scope.attempts 	  = result.attempts;
						}
						
						if(result.migrated == false && !result.eligible)
						{
							$scope.migrate = false;
						}

						if(result.migrated == false)
						{
							$scope.show.password = true;
							$scope.show.message  = true;
						}
					}
					else
					{
						$scope.show.password  = true;
						$scope.show.message   = true;
						$scope.migrate = false;
					}
                }); 
			}

			return false;
		};
		
		$scope.check_ciam_user = function() {
			
			if($scope.form.username)
			{
				$scope.error.issue = false;
				$scope.error.locked = false;

				//confirm creds + get ciam data
				var p = [ UserService.check_ciam_user($scope.form.username,$scope.form.password)];

                $q.all(p).then(function(results){
           
                    var result = results[0];

                    if(result.success)
                    {
						if($scope.migrate)
						{
							$scope.email = result.email;
							$scope.guid  = result.guid;
							$scope.form.ciam_username = $scope.email;
							$scope.ui.view = 'migration';
						}
						else
						{
							//don't migrate just send them to login
							$scope.login();
						}
                    }
                    else
                    {
                    	if(result.message == 'locked')
                    	{
                    		$scope.error.locked = true;
                    	}
                    	else
                    	{
                    		$scope.error.issue = true;
                    	}

                    	$scope.form.password = '';
                    	$scope.email = '';
                    }
                }); 
						
			}

			return false;
		};

		$scope.skip_ciam_migration = function() {
			// Decrement ciam attempts
			if($scope.form.username)
			{
				var p = [ UserService.update_ciam_attempts($scope.form.username)];

                $q.all(p).then(function(results){
           
                    var result = results[0];
                   	if(result.success)
					{
						// Log user in using old method
						$scope.login();
					}
                }); 
			}
		};

		$scope.ciam_username_change = function()
		{
			$scope.code_sent = false;
		};

		$scope.continue_ciam_migration = function() {
			var email_regex = /[_A-Za-z0-9-]+(.[_A-Za-z0-9-]+)*@[A-Za-z0-9]+(.[A-Za-z0-9]+)*(.[A-Za-z]{2,})/;

			if (!email_regex.test($scope.form.ciam_username)){
				$scope.error.email = true;
				return false;
			}

			//hide our old error
			$scope.error.email = false;
			$scope.error.issue = false;

			if ($scope.form.ciam_username)
			{

				//check for user uniqueness in CF first - Then Send a verification code;
				var p = [ UserService.ciam_unique_user($scope.form.ciam_username,$scope.guid)];

				 $q.all(p).then(function(results) {
           
                    var result = results[0];

                    if(result.success)
                    {
						$scope.send_verification_code($scope.form.ciam_username,$scope.guid);
					}
					else
					{
						$scope.error.unique = true;
					}
				});
			}
		}

		$scope.send_verification_code = function(email,guid)
		{
			//double check we have a username
			if ($scope.form.ciam_username && !$scope.code_sent)
			{
				//don't send it like 30 times, only 1
				$scope.code_sent = true;

				//Sends verification code to the specified email
				var p = [ UserService.send_verification_code(email,guid)];

	            $q.all(p).then(function(results) {
	       
	                var result = results[0];

	                if(result.success)
	                {
						$scope.ui.view = 'verification';
						$scope.ciam_username = $scope.form.ciam_username;
					}
				});
        	}
        	else if($scope.code_sent)
        	{
        		$scope.ui.view = 'verification';
        	}
		};

		$scope.verify_ciam_code = function() {
			$scope.show.resend = false;

			if ($scope.form.ciam_code) 
			{
				$scope.error.code_required = false;
				$scope.error.issue = false;

				// Verifies the code the user enters
				var p = [ UserService.verify_ciam_code($scope.guid , $scope.ciam_username, $scope.form.ciam_code)];

                $q.all(p).then(function(results) {
           
                    var result = results[0];

                    if(result.success)
                    {
						if (result.match) {
							//set our username to the new confirmed username
							$scope.form.username = $scope.ciam_username;

							$scope.confirm_password();
						}
						else 
						{
							$scope.error.issue = true;
						}
					}
				});
			}
			else
			{
				$scope.error.code_required = true;
			}
		}

		$scope.resend_ciam_code = function() 
		{
			if ($scope.ciam_username)
			{
				$scope.show.resend = true;
				$scope.error.issue = false;
				$scope.error.expired = false;
				$scope.code_sent = false;

				$scope.send_verification_code($scope.form.ciam_username,$scope.guid);
			}
		};

		$scope.keydown = function(e,name)
		{
			if(e.which == 13)
			{
				switch(name)
				{
					case 'migrate':
						$scope.continue_ciam_migration();
					break;


					case 'verify':
						$scope.verify_ciam_code();
					break;
				}
			}

			return false;
		};

		$scope.navigate = function(id)
		{
			$scope.ui.view = id;
		};

		$scope.getAgencyAppointmentUrl = function() {
			return policyManagerRoot + "index.cfm?action=agencyAppointment:main.home";
		};


		$scope.forgotPassword = function(){
			var iFrameElement = document.getElementById('ssoFrame' );

			iFrameElement.src = 'about:blank';
			$scope.ui.submitted = true;

			var iframeSrc = ssoApiUrl + "?action=sso:authentication.login&logo=none&returnType=function&forgotPassword=1";

			iframeSrc += "&returnURL=" + $window.encodeURIComponent( $window.location.origin + $window.location.pathname );

			iFrameElement.src = $sce.trustAsResourceUrl( iframeSrc );
		};


		$scope.getLoginFormAction = function() {

			var ssoUrl = ssoApiUrl  + "?action=sso:authentication.processLogin&logo=none&returnType=function";

			ssoUrl += "&returnURL=" + $window.encodeURIComponent( $window.location.origin + $window.location.pathname );

			return ssoUrl;

		};

		$scope.agreeToTerms = function()
		{
			var iFrameElement = document.getElementById('ssoFrame' );

			iFrameElement.src = 'about:blank';
			$scope.ui.submitted = true;

			var iframeSrc = ssoApiUrl + "?action=sso:authentication.agreeToTerms&logo=none&returnType=function";

			iframeSrc += "&returnURL=" + $window.encodeURIComponent( $window.location.origin + $window.location.pathname );

			iFrameElement.src = $sce.trustAsResourceUrl( iframeSrc );
		};


		$scope.login = function()
		{
			//we know they are valid log us in
        	document.getElementById('ssoFrame' ).src = 'about:blank';
			var formElement = document.getElementById( 'login-form' );

			$scope.ui.submitted = true;
			$scope.show.message = false;
			$scope.ui.view 		= 'login';

			formElement.action = $scope.getLoginFormAction();
			formElement.submit();
		};

		$scope.confirm_password = function()
		{
			$scope.error.issue = false;
			$scope.error.locked = false;

			var p = [ UserService.okta_login($scope.ciam_username, $scope.form.password)];

            $q.all(p).then(function(results) {
       
                var result = results[0];

                if(result.success)
                {
					$scope.redirect = result.url;
					$scope.migrate_user();
				}
				else
				{
					$scope.ui.view = 'password';
					if(result.error == 'unauthorized')
					{
						$scope.error.issue = true;
					}

					if(result.error == 'locked')
					{
						$scope.error.locked = true;
					}
				}
			});
		};

		$scope.migrate_user = function()
		{
			//set our username to the new confirmed username
			var m = [ UserService.migrate_ciam_user($scope.guid,$scope.ciam_username,false)];

			$q.all(m).then(function(migration_results) {
		
				var migration_result = migration_results[0];

				if(migration_result.success)
				{
					$('.processing-modal').modal('show');
					$window.location = $scope.redirect;
				}
			});
		}
		
	}

	return LoginController;

});
