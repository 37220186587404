define('components/insuredRegistration/termsAndConditions/app',['require','components/insuredRegistration/termsAndConditions/directive/termsAndConditions','components/insuredRegistration/termsAndConditions/controller/TermsAndConditionsController','common/service/PublicContentService'],function(require) {
	"use strict";

	var termsAndConditions = require("components/insuredRegistration/termsAndConditions/directive/termsAndConditions"),
		TermsAndConditionsController = require("components/insuredRegistration/termsAndConditions/controller/TermsAndConditionsController"),
		PublicContentService = require("common/service/PublicContentService");

	var component = angular.module("mPortal.insuredRegistration.termsAndConditions", []);

	component.directive("termsAndConditions", termsAndConditions);
	component.controller("TermsAndConditionsController", TermsAndConditionsController);
	component.factory("PublicContentService", PublicContentService);

	return component;

});
